html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-c {
  justify-content: center;
}
.flex-e {
  justify-content: flex-end;
}
.flex-b {
  justify-content: space-between;
}
.flex-a {
  justify-content: space-around;
}
.ant-menu-sub {
  background: #456268 !important;
}
.container {
  display: flex;
}
.container .nav-left {
  background: #456268;
  width: 16%;
  min-width: 218px;
  height: 100vh;
}
.container .nav-left .ant-menu {
  background: #456268;
}
.container .main {
  flex: 1;
  background: #f1f3f5;
  max-width: calc(100% - 218px);
}
.container .main .main-body {
  overflow: auto;
  margin-top: 20px;
  height: calc(100vh - 124px);
}
.container .main .main-body .main-content {
  position: relative;
  padding: 20px;
  padding-top: 0;
}
.my-loading {
  display: none;
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999999;
}
.my-loading p {
  position: fixed;
  padding: 0 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  line-height: 80px;
  font-size: 16px;
  color: #fff;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 6px;
}
.BMap_cpyCtrl {
  display: none;
}
.anchorBL {
  display: none;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
